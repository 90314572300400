import strings from '@json/strings';
import { Box, Card, CardContent, CardMedia, Container, Grid, Link, Stack, Typography } from '@mui/material';
import { useStyles } from '@styles';
// import ReactPlayer from "react-player" 
import readyGoImg from '@assets/RDSG_Final_Updated__2.png';
import Vimeo from '@u-wave/react-vimeo';
import { useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

const StartHereOnline = () => {
  const classes = useStyles()
  // const isBig = useMediaQuery(`(min-width:1200px)`)

  useEffect(() => {
    document.body.classList.add('start-layout')
    return () => {
      document.body.classList.remove('start-layout')
    }
  }, [])

  return (
    <Stack
      className={classes.gettingStartedView}
      sx={{ overflow: `auto` }}
    >
      <Box sx={{ backgroundColor: '#000', marginTop: `0` }}>
        <Typography
          variant='h1'
          color='white'
          className={classes.pageHeader}
          sx={{ marginTop: `25px`, fontWeight: `bold`, textAlign: `left`, marginBottom: '25px' }}>
          <Container>
            Hi there! <br />
            Ready to manage a  <br />
            lifetime of photos?
          </Container>
        </Typography>
      </Box>
      <Container sx={{ backgroundColor: '#fff', padding: '0' }}>

        <Grid sx={{ justifyContent: 'left', marginTop: '0', padding: '0px' }}>


          <Stack
            justifyContent="left"
            direction={{ xs: 'row', md: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
            useFlexGap
            flexWrap="wrap"
            className={classes.contentContainer}>
            <Container >

              <Card sx={{ maxWidth: '100%' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  Activating Mylio Photos+
                </Typography>
                <Container className={classes.videoPlayerWrapper}>

                  <BrowserView>
                    <Vimeo
                      video="947396010"
                      responsive
                      webkit-playsinline
                    />

                  </BrowserView>
                  <MobileView>
                    <Vimeo
                      video="947396010"
                      responsive
                    />

                  </MobileView>

                </Container>
                <CardContent>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    If you bought Mylio Photos+ on the Mylio website, please check your email for your License Key and activation instructions.
                  </Typography>
                </CardContent>
              </Card>



              <Card sx={{ maxWidth: '100%' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.gettingStarted.addFilesHeader}
                </Typography>
                <Container className={classes.videoPlayerWrapper}>

                  <BrowserView>

                    <Vimeo
                      video="868833702"
                      responsive
                      webkit-playsinline
                    />

                  </BrowserView>
                  <MobileView>

                    <Vimeo
                      video="868833702"
                      responsive
                    />

                  </MobileView>

                </Container>
                <CardContent>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.gettingStarted.addFilesContent}
                  </Typography>
                </CardContent>
              </Card>


              <Card sx={{ maxWidth: '100%', marginBottom: '30px' }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                    Ready, Set, Go!
                  </Typography>
                  <CardMedia className={classes.videoPlayerWrapper}>
                    <img
                      src={readyGoImg}
                      alt="Ready Set Go"
                      width='100%'
                    />
                  </CardMedia>

                  <Typography variant="body2" color="black" className={classes.startGuideContent}>

                    Daily live sessions covering Mylio Photos essentials for beginners and complemented by live Q&A <br />
                    <Link underline="none" target="_blank" href="http://mylio.com/readysetgo"> http://mylio.com/readysetgo</Link>
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{ maxWidth: `100%`, marginBottom: '30px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.gettingStarted.filterSmarttagHeader}
                </Typography>
                <Container className={classes.videoPlayerWrapper}>

                  <Vimeo
                    video="868833742"
                    responsive
                  />

                </Container>
                <CardContent>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.gettingStarted.filterSmarttagContent}
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{ maxWidth: `100%`, marginBottom: '30px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.gettingStarted.editPhotosHeader}
                </Typography>
                <Container className={classes.videoPlayerWrapper}>

                  <Vimeo
                    video="868833840"
                    responsive
                  />

                </Container>
                <CardContent>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.gettingStarted.editPhotosContent}
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{ maxWidth: `100%`, marginBottom: '30px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.gettingStarted.shareExportHeader}
                </Typography>
                <CardMedia className={classes.videoPlayerWrapper}>

                  <Vimeo
                    video="868833951"
                    responsive
                  />

                </CardMedia>
                <CardContent>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.gettingStarted.shareExportContent}
                  </Typography>
                </CardContent>
              </Card>


              <Card sx={{ maxWidth: `100%`, marginBottom: '30px' }}>
                <Typography gutterBottom variant="h5" component="div" color='black' className={classes.startGuideHeader} sx={{ fontWeight: "700" }}>
                  {strings.views.gettingStarted.backupHeader}
                </Typography>
                <CardMedia className={classes.videoPlayerWrapper}>
                  <Vimeo
                    video="868834054"
                    responsive
                    webkit-playsinline='true'
                  />
                </CardMedia>

                <CardContent>
                  <Typography variant="body2" color="black" className={classes.startGuideContent}>
                    {strings.views.gettingStarted.backupContent}
                  </Typography>
                </CardContent>
              </Card>


              {/* <Button variant="contained" href="https://mylio.com/quickstart-pdf/" sx={{ marginBottom:"50px" }}>Download Quick Start Guide</Button> */}

            </Container>
          </Stack>
        </Grid>
      </Container>

    </Stack >

  )
}
export default StartHereOnline
