import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

const KnowledgeBase = () => {
  const [isLoading, setIsLoading] = useState(true);

  const checkContainer = () => {
    let containerAttempts = 0;
    const maxContainerAttempts = 120;

    const verifyContainer = () => {
      if (document.getElementById('intercom-container')) {
        setIsLoading(false);
        return;
      }

      if (containerAttempts < maxContainerAttempts) {
        containerAttempts++;
        window.Intercom('showSpace', 'help');
        setTimeout(verifyContainer, 500);
      }
    };

    setTimeout(verifyContainer, 500);
  };

  useEffect(() => {
    document.body.classList.add('help-layout');

    let attempts = 0;
    const maxAttempts = 120;
    
    const tryIntercom = () => {
      if (window.Intercom) {
        window.Intercom('showSpace', 'help');
        checkContainer();
        return;
      }

      if (attempts < maxAttempts) {
        attempts++;
        setTimeout(tryIntercom, 500);
      }
    };

    tryIntercom();

    return () => {
      document.body.classList.remove('help-layout');
    };
  }, []);

  return (
    <Box 
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      id='MylioIntercomSpace'
    >
      {isLoading && <CircularProgress />}
    </Box>
  );
};

export default KnowledgeBase;