import { useEffect } from "react"

const Community = () => {

  useEffect(() => {
    document.body.classList.add('community-layout')
    return () => {
      document.body.classList.remove('community-layout')
    }
  }, [])

  return (
    <iframe is="x-frame-bypass" width='100%' height='100%' src={'https://community.mylio.com/feed'}></iframe>
  )
}

export default Community