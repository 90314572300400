import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

const Help = () => {
 const [isLoading, setIsLoading] = useState(true);
 const [error, setError] = useState<string | null>(null);

 const checkContainer = () => {
   let containerAttempts = 0;
   const maxContainerAttempts = 120;

   const verifyContainer = () => {
     if (document.getElementById('intercom-container')) {
       setIsLoading(false);
       return;
     }

     if (containerAttempts < maxContainerAttempts) {
       containerAttempts++;
       window.Intercom('showNewMessage', '');
       setTimeout(verifyContainer, 500);
     } else {
       setError('Failed to load chat. Please refresh the page.');
       setIsLoading(false);
     }
   };

   setTimeout(verifyContainer, 500);
 };

 useEffect(() => {
   document.body.classList.add('chat-layout');
   
   let attempts = 0;
   const maxAttempts = 120;
   
   const tryIntercom = () => {
     if (window.Intercom) {
       window.Intercom('showNewMessage', '');
       checkContainer();
       return;
     }

     if (attempts < maxAttempts) {
       attempts++;
       setTimeout(tryIntercom, 500);
     } else {
       setError('Failed to load chat. Please refresh the page.');
       setIsLoading(false);
     }
   };

   tryIntercom();

   return () => {
     document.body.classList.remove('chat-layout');
   };
 }, []);

 if (error) {
   return (
     <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
       {error}
     </Box>
   );
 }

 return (
   <Box 
     sx={{
       width: '100%',
       height: '100vh',
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center'
     }}
     id='MylioIntercomSpace'
   >
     {isLoading && <CircularProgress />}
   </Box>
 );
};

export default Help;