import { Box, CssBaseline, Grid, ThemeProvider } from '@mui/material'
import { useStyles } from '@styles'
import { theme } from '@themes'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import SideNav from './components/sideNav'
import Community from './views/community'
import Concierge from './views/concierge'
import Error from './views/error'
import Chat from './views/help'
import Learn from './views/learn'
import LearnMore from './views/learnMore'
import News from './views/news'
import Plans from './views/plans'
import StartHereOffline from './views/startHereOffline'
import StartHereOnline from './views/startHereOnline'

declare global {
  interface Window {
    Intercom: any,
    intercomSettings: any
  }
}

const App = () => {
  return (
    <Box className={useStyles().app} id='App'>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid>
          <Router>
            <Routes>
              <Route path="/" Component={StartHereOnline} />
              <Route path="/offline" Component={StartHereOffline} />
              <Route path="/chat" Component={Chat} />
              <Route path="/plans" Component={Plans} />
              <Route path="/learn" Component={Learn} />
              <Route path="/news" Component={News} />
              <Route path="/error" Component={Error} />
              <Route path="/community" Component={Community} />
              <Route path="/concierge" Component={Concierge} />
              <Route path="/learnmore" Component={LearnMore} />
              <Route path="/?tab=1" Component={StartHereOnline} />
              <Route path="/chat?tab=2" Component={Chat} />
              <Route path="/learn?tab=3" Component={Learn} />
              <Route path="/news?tab=5" Component={News} />
              <Route path="/plans?tab=4" Component={Plans} />
              <Route path="/learnmore?tab=6" Component={LearnMore} />
            </Routes>
          </Router>
        </Grid>
        <SideNav />
      </ThemeProvider>
    </Box>
  )
}

export default App
